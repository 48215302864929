#amazon {
    text-align:left;
    background-image: url('../../wedding-registry.png');
    background-repeat: no-repeat;
    display:block;
    width:200px;
    height:100px;
    text-indent:-9999em
}

#target {
    text-align:left;
    background-image: url('../../target-registry.png');
    background-repeat: no-repeat;
    display:block;
    width:200px;
    height:100px;
    text-indent:-9999em
}

#pottery-barn {
    text-align:left;
    background-image: url('../../pottery-barn.jpg');
    background-repeat: no-repeat;
    display:block;
    width:200px;
    height:100px;
    text-indent:-9999em
}