.arrow {
    box-sizing: border-box;
    height: 3vw;
    width: 3vw;
    border-style: solid;
    border-color: black;
    transition: border-width 150ms ease-in-out;
    text-align: center;
}

.down {
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.down:hover {
    border-bottom-width: 5px;
    border-right-width: 5px;
}

.up {
    border-width: 2px 0 0 2px;
    transform: rotate(45deg);
}

.up:hover {
    border-top-width: 5px;
    border-left-width: 5px;
}

.arrow-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15vh;
    width: 100%;
}