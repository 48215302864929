#reg-container {

    position: fixed;
    z-index: 50;

}

.reg-button {
    margin-top: 20px;
    margin-left: 20px;
    color: #fff !important;
    text-transform: uppercase;
    background: #052bed;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 40px;
    padding-left: 40px;
    border-radius: 5px;
    display: inline-block;
    border: none;
}

.reg-button:hover {
    background: #10a4ed;
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
    -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
    box-shadow: 5px 40px -10px rgba(0,0,0,0.57);
    transition: all 0.4s ease 0s;
}