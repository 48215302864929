#left-container {
    width:60%;
    min-height: 100vh;
    color:white;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('../../landscape.png');
    margin-left: 0;
    position:fixed;
}

#left-content {
    max-width: 50%;
    text-align: center;
    margin-top: 20%;
    margin-right: 10%;
    float: right;
    position: relative;
    min-width: 455px;
}

.countdown {
    font-size: 4vw;
    font-weight: bold;
}

@media screen and (max-width: 1024px) {
    #left-container {
        position: relative;
        width: 100%;
        display: block;
        min-height: 50vh
    }

    #left-content {
        margin-top:  5%;
        margin-right: 0;
    }
}

@media screen and (max-width : 736px) {
    #left-container {
        position: relative;
        width: 100%;
        display: block;
        min-height: 35vh
    }
}

@media screen and (max-height : 420px) {
    #left-container{
        display: none;
    }
}

