.pane {
    align-items: center;
    text-align: center;
    height: 100vh;
    display: table-cell;
    vertical-align: middle;
    width: 20%
}

#date {
    font-size: 2vw;
    padding-bottom: 5%;
    font-family: 'Gotham Medium', serif;
}

#location {
    font-size: 1.5vw;
    font-family: 'Gotham Medium', serif;
}

.container {
    display: block;
    padding-right: 7%;
    padding-left: 7%;
}

.dayHeader {
    font-size: 2vw;
    font-weight: normal;
    text-align: left;
    padding-bottom: 2%
}

.day-organizer {
    font-family: 'Gotham Medium', serif;
    width: 100%;
    font-size: 1.5vw;
}

.organizer-left {
    width:30%;
    text-align: left;
    float: left;
    padding-bottom: 2%;
}

.organizer-right {
    width:70%;
    text-align: left;
    display: inline-block;
    float: right;
    padding-bottom: 2%;
}

.dayTextMedium {
    font-size: 1.4vw;
    text-align: left;
    padding-bottom: 2%;
}

.dayTextSmall {
    font-size: 1.3vw;
    text-align: left;
    padding-bottom: 2%;
}

.heart {
    font-family: 'Webdings', serif;
    font-size: 1.5vw;
}

.title {
    text-align: left;
    font-size: 1.5vw;
}

.bold {
    font-weight: bold;
}

@media screen and (max-width : 736px)
and (orientation: portrait) {

    .dayHeader {
        font-size: 5.3vw;
    }

    .day-organizer {
        font-size: 5vw;
    }

    .dayTextSmall {
        font-size: 4.7vw;
    }

    .dayTextMedium {
        font-size: 4.8vw;
    }

    #date {
        font-size: 5.3vw;
        font-weight: bold;
    }

    #location {
        font-size: 4.8vw;
        padding-left: 2%;
        padding-right: 2%;
    }
}