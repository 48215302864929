.value {
    padding-bottom: 10px;
    width: 200px;
    display: inline-block;
    margin-right: 5px;
}

.time-key {
    padding-bottom: 10px;
    display: inline-block;
    width: 200px;
    margin-left: 50px;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}