#right-container {
    width:40%;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    float:right;
    display: inline-block;
}

@media screen and (max-width: 1025px) {
    #right-container {
        width: 100%;
    }

    #home {
        height: 50vh;
        padding-top: 10%;
        vertical-align: top;
    }

    .heart {
        display: none;
    }
}

@media screen and (max-height : 420px) {
    #home{
        height: 100vh;
        vertical-align: middle;
        padding-top: 0;

    }
}
